import React, { useState, ChangeEvent } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLs from 'src/config/api/Urls'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import AcceptTerms from 'src/components/AcceptTerms'
import { sendCDPFormData } from 'src/shared/helpers'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import CountdownInterDay from 'src/components/Countdown/inter-day/_index'
import ModalMenssage from '../_components/_modal/_index'
import ImageWebp from 'src/components/ImageWebp'

import { Modal } from 'src/components/Modal'

import ChevronDown from '@interco/icons/build-v4/orangeds/LG/chevron-down'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ProdutosAte80 from './../../assets/image/produtos-ate-80-inter-day-v5.svg'

import selectJSON from '../../assets/data/select.json'

import { Section, Card, CardSelect, Button, Select, Summary, Check } from './style'

type Category = {
  text: string;
  slug: string;
  select: boolean;
}

interface IFormValues {
  nome: string;
  email: string;
  cpfCnpj: string;
  conteudo01: string;
}

const HeroInterDay = () => {
  const domReady = useDomReady()
  const width = useWidth()
  const [ categoryList, setCategoryList ] = useState<Category[]>(selectJSON)
  const [ accept, setAccept ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ openModal, setOpenModal ] = useState(false)
  const [ type, setType ] = useState('success')
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ openSelect, setOpenSelect ] = useState(false)

  const formSubmit = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      cpfCnpj: data.cpfCnpj.replace(/\D/g, ''),
      aceite: accept,
      campanha: 'Inter Day 2024',
      conteudo02: 'Site',
      conteudo03: 'Sim',
    }

    try {
      await axios.post(`${URLs.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      sendCDPFormData({ formName: 'Inter Day 2023', cpf: data.cpfCnpj, email: data.email })
      setOpenSelect(false)
      setType('success')
      setAccept(false)
      setLoading(false)
      setOpenModal(true)

      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Inter Day 2024',
        element_name: 'Quero benefícios VIP',
        element_action: 'submit',
        step: 'success',
      })
    } catch (err) {
      setLoading(false)
      setType('error')
      setOpenModal(true)
    }
  }

  const handleCheck = (slug: string) => {

    const newList = categoryList.map((item: Category) => {
      if (slug === item.slug) {
        return {
          ...item,
          select: !item.select,
        }
      } else {
        return item
      }
    })
    setCategoryList(newList)
  }

  const ModalMenssagem = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <ModalMenssage type={type} setOpen={setOpenModal} />
    </Modal>
  )

  return (
    <Section id='hero-inter-day' isOpenSelect={openSelect}>
      {
        ModalMenssagem
      }
      <CountdownInterDay />
      <div className='container'>
        <div className='row justify-content-md-between'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6 text-center text-md-left my-4 my-md-0'>
            <h1 className='font-citrina fs-28 lh-34 fs-md-40 lh-md-44 fs-lg-48 lh-lg-60 fs-xl-60 lh-xl-63 text-white fw-600 mt-md-2 mt-lg-0 mb-3'>
              <span className='d-block'>Inter Day 2024.</span>
            </h1>
            {width < WIDTH_MD &&
              <ImageWebp
                pathSrc={ProdutosAte80}
                altDescription='Imagem representando o desconto da promoção Inter Day'
                arrayNumbers={[ 285 ]}
                arrayNumbersHeight={[ 55.78]}
              />
            }
            {width >= WIDTH_MD &&
              <>
                <p className='font-citrina fs-md-20 lh-md-24 fs-lg-24 lh-lg-28 mb-0 mt-md-3 text-white pr-3'>
                  <span className='d-block d-md-inline d-xl-block'>Super ofertas pra zerar sua lista de desejos.</span> Dia 10/7, no Shopping do Inter.
                </p>
                <div className='mt-4 mt-md-5'>
                  <ImageWebp
                    pathSrc={ProdutosAte80}
                    altDescription='Imagem representando o desconto da promoção Inter Day'
                    arrayNumbers={[ 235, 338, 428, 428, 428 ]}
                    arrayNumbersHeight={[ 100, 101, 101, 101.03, 121.03 ]}
                    className='ml-n1'
                  />
                </div>
              </>
            }
          </div>

          <div className='col-12 col-md-6 col-xl-5 px-md-0'>
            <Card isOpenSelect={openSelect}>
              <p className='font-citrina text-white fs-22 lh-24 fs-lg-28 lh-lg-34 fs-xl-33 lh-xl-32 fw-600 text-center mb-2'>
                <span className='d-block'>Desbloqueie seu cupom:</span> até 10% de cashback EXTRA
              </p>
              <form onSubmit={handleSubmit(formSubmit)}>
                <div className='row'>
                  <div className={`col-12 ${errors.nome && 'error'}`}>
                    <label className='fs-12 lh-14 mt-3 mb-1 text-white d-block'>Nome</label>
                    <input
                      placeholder='Digite seu nome completo'
                      type='text'
                      name='nome'
                      ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.validFullName(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    />
                    {errors.nome && <p className='fs-12  mb-0 text-right'>{errors.nome.message}</p>}
                  </div>
                  <div className={`col-12 ${errors.email && 'error'}`}>
                    <label className='fs-12 lh-14 text-white d-block mt-2 mb-1'>E-mail</label>
                    <input
                      placeholder='Digite seu e-mail'
                      type='text'
                      name='email'
                      ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    />
                    {errors.email && <p className='fs-12  mb-0 text-right'>{errors.email.message}</p>}
                  </div>
                  <div className={`col-12 ${errors.cpfCnpj && 'error'}`}>
                    <label className='fs-12 lh-14 text-white d-block mt-2 mb-1'>CPF</label>
                    <input
                      ref={register({
                        required: 'Digite um CPF válido',
                        validate: {
                          isCpf: (value: string) => Validations.cpforcnpj(value) || 'CPF Inválido',
                        },
                      })}
                      placeholder='Digite seu CPF'
                      type='text'
                      name='cpfCnpj'
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpfCnpj', Masks.MaskCPF(event.currentTarget.value))}
                    />
                    {errors.cpfCnpj && <p className='fs-12 mb-0 text-right'>{errors.cpfCnpj.message}</p>}
                  </div>
                  <div className={`col-12 ${errors.conteudo01 && 'error'}`}>
                    <label className='fs-12 lh-14 text-white mt-2 mb-1'>Lista de desejos</label>
                    <input
                      className='d-none'
                      type='text'
                      value={categoryList.filter((item: Category) => item.select).map((item: Category) => item.slug).join(',')}
                      name='conteudo01'
                      ref={register({
                        required: true,
                      })}
                    />
                    <Select>
                      <Summary onClick={() => setOpenSelect(!openSelect)}>
                        <p className='fs-14 lh-17 text-white fw-600 mb-0'>Selecione as categorias</p>
                        <ChevronDown height={24} width={24} color='#FF7A00' className='chevron' />
                      </Summary>
                      <CardSelect isOpenSelect={openSelect} className='mt-4'>
                        {
                          categoryList.map((item: Category) => (
                            <Check
                              className='d-flex align-items-center text-white mb-2 px-0'
                              key={item.text}
                              type='button'
                              onClick={() => handleCheck(item.slug)}
                              disabled={categoryList.filter((category: Category) => category.select).length > 4 && !item.select}
                            >
                              { item.select
                                ? <OrangeIcon size='MD' color='#ff7a00' icon='checkbox-on' />
                                : <OrangeIcon size='MD' color='#B6B7BB' icon='checkbox-off' />
                              }
                              <span className='fs-14 lh-17 text-white'>{item.text}</span>
                            </Check>
                          ))
                        }
                      </CardSelect>
                    </Select>
                    {errors.conteudo01 && <p className='fs-12 mb-0 text-right'>Selecione as categorias</p>}
                  </div>
                  <div className='col-12 mt-2'>
                    <AcceptTerms
                      accept={accept}
                      setAccept={setAccept}
                      name='aceite'
                      styles='fs-10 lh-12 text-white'
                      linkColor='#ffffff'
                      label='Quero receber comunicações da Lista VIP e concordo com a'
                      acceptLink='https://marketplace.bancointer.com.br/termos/Regulamento_Campanha_Teste_Drive_Premiado_Inter_Cel_27062024.pdf'
                    />
                    <Button
                      type='submit'
                      title='Liberar cupom'
                      disabled={!accept || loading}
                    >
                      {loading ? 'Enviando...' : 'Liberar cupom' }
                    </Button>
                  </div>
                </div>
              </form>
            </Card>
            {width < WIDTH_MD &&
              <div className='text-center'>
                <p className='font-citrina fs-16 lh-20 text-white my-4 px-3'>
                  Cadastre-se pra ganhar ainda mais cashback nas super ofertas do dia 10/7 no Shopping do Inter.
                </p>
              </div>
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroInterDay
